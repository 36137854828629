.menu-popup {
  span {
    padding-left: 8px;
  }

  span.sup {
    font-size: 10px;
    font-weight: bold;
    color: #9e9e9e;
    display: block;
    padding: 0;
    margin-top: -8px;

    &.warn {
      color: red;
    }
  }

  .menu-icon {
    text-align: center;
    width: 24px;
    height: 24px;

    img {
      width: 22px;
    }
  }

  .year-selection {
    margin-top: -8px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid lightgray;

    span {
      padding: 0;
      text-align: center;
      flex-grow: 1;
    }

    button {
      border-radius: 0;
    }
  }
}

.privacy-content {
  p {
    margin: 0;
    text-align: justify;
  }

  li a {
    word-break: break-all;
  }

  h4 {
    margin-bottom: 2px;
  }
}
