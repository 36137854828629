.password-input {
  .content {
    text-align: center;
  }

  .warning {
    font-weight: bold;
    color: red;
    margin-top: 0;
  }

  .password {
    max-width: 217px;
    margin: 0 auto;
  }
}
