.lockscreen {
  &.placeholder {
    top: calc(50% - 150px);
  }

  .input {
    background-color: #DFDFDF;
    border-bottom-color: #818181;
  }

  .input-wrapper {
    max-width: 217px;
    margin: 0 auto;
  }
}
