.day {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: #37474f;

  &.filler {
    cursor: not-allowed;
    background: repeating-linear-gradient(-45deg, #37474f, #37474f 3px, #282c34 3px, #282c34 6px)
  }

  &.blink {
    animation: blinking 2s linear infinite;
  }

  .note-indicator {
    display: none;
    background-color: white;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    position: absolute;
  }

  .label {
    position: absolute;
    color: white;
    animation: fadeout 3s ease-in 1 forwards;
  }

  @media screen and (orientation: landscape) {
    .label {
      font-size: 1.5vmin;
    }
  }

  @media screen and (orientation: portrait) {
    .label {
      font-size: 2vmin;
    }
  }

  @keyframes blinking {
    50% {
      background-color: white;
    }
  }

  @keyframes fadeout {
    100% {
      opacity: 0;
    }
  }
}

.year:hover {
  .day .note-indicator:only-child { // only displayed if the pixel is not labeled
    display: block;
  }

  .day .label {
    animation: fadeout 0s linear 1 reverse;
  }
}
