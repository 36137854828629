body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #282c34;
}

body, html, #root {
  height: 100%;
}

.placeholder {
  position: relative;
  top: calc(50% - 100px);
  width: 100%;
  text-align: center;
  color: white;

  svg.icon {
    font-size: 6.3rem;

  }

  p {
    margin: 0;
  }

  &.error {
    color: #D32F2F;
  }
}

.dialog-title span {
  vertical-align: text-bottom;
  padding-left: 5px;
}
